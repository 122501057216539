html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

/* Helpers & Animations */
.clearfix::after {
  content: "";
  clear: both;
  display: table; }

.isHidden {
  opacity: 0; }

.isVisible {
  opacity: 1;
  -webkit-transition: opacity 1000ms;
  transition: opacity 1000ms; }

@-webkit-keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0); }
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0); }
  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0); } }

@keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0); }
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0); }
  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0); } }

/* Main SCSS */
.logo-header {
  display: inline-block;
  margin: 20px 40px; }

.hero {
  width: 100%;
  padding: 40px; }
  .hero .text {
    position: absolute;
    margin-top: 60px; }
  .hero__title {
    color: #028f76;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 6px; }
  .hero__subtitle {
    font-family: 'Playfair Display', serif;
    font-weight: 400;
    font-size: 50px;
    padding: 0;
    margin: 30px 0;
    line-height: 60px;
    max-width: 60%; }
  .hero__content {
    color: #858585;
    width: 350px;
    line-height: 1.8; }
  .hero__image {
    float: right;
    box-shadow: 0px 0px 36px 4px #cccccc; }

.container {
  color: #333;
  background-size: cover;
  position: relative;
  width: 100%;
  min-height: 100vh; }
  .container h1 {
    font-family: 'Playfair Display', serif;
    font-weight: 400;
    font-size: 50px;
    padding: 0;
    margin: 30px 0;
    line-height: 70px; }
  .container h2 {
    color: #FFF;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 6px;
    font-weight: 700; }
  .container.light {
    color: #FFF; }
  .container.dark {
    color: #000; }

.content {
  margin: 80px 0 40px; }
  .content.left {
    text-align: left; }
  .content.center {
    text-align: center; }

footer {
  text-align: center;
  background-color: #f5f5f5;
  padding: 40px 20px; }
  footer .logo {
    width: 200px;
    height: 100px;
    background-color: #CCC;
    margin: auto; }
  footer .links {
    margin: 20px 0; }
    footer .links ul {
      padding: 0;
      margin: 0; }
      footer .links ul li {
        list-style-type: none;
        display: inline-block;
        margin: 10px 30px; }
        footer .links ul li a {
          color: #7f988a;
          font-weight: 700;
          text-transform: uppercase;
          text-decoration: none;
          font-size: 16px; }
  footer .contact {
    color: #98a79f;
    margin: 10px 0; }
  footer .legal {
    color: #98a79f;
    font-size: 14px; }

header .menu-closed .text {
  position: absolute;
  font-weight: 700;
  top: 30px;
  right: 60px; }

header .menu-closed .open-menu-icon {
  float: right;
  right: 0;
  top: 0;
  padding: 20px;
  margin: 10px;
  cursor: pointer; }

header .menu-opened {
  background-image: url(/images/header/background.jpg);
  background-size: cover;
  background-color: #000;
  height: 100vh; }
  header .menu-opened .language-selector {
    padding: 20px;
    float: left; }
    header .menu-opened .language-selector span {
      color: #028f76;
      margin: 0 10px;
      cursor: pointer; }
      header .menu-opened .language-selector span.active {
        color: #000; }
  header .menu-opened .close-menu-icon {
    padding: 20px;
    margin: 10px;
    cursor: pointer;
    display: inline-block; }
  header .menu-opened .right {
    background-color: #FFF;
    width: 500px;
    float: right;
    height: 100vh;
    text-align: right; }
    header .menu-opened .right .locations {
      color: #028f76; }
    header .menu-opened .right ul {
      padding: 15px;
      margin: 0; }
      header .menu-opened .right ul li {
        list-style-type: none;
        text-align: right; }
        header .menu-opened .right ul li a {
          font-family: 'Playfair Display', serif;
          font-weight: 300;
          font-size: 28px;
          color: #3f4843;
          padding: 10px;
          display: inline-block;
          text-decoration: none; }
          header .menu-opened .right ul li a.active {
            color: #028f76; }
            header .menu-opened .right ul li a.active::before {
              content: "\A0\A0\A0\A0\A0\A0\A0";
              text-decoration: line-through;
              margin-right: 10px; }
        header .menu-opened .right ul li .social-media {
          margin-top: 20px; }
          header .menu-opened .right ul li .social-media img {
            margin: 0 10px; }
        header .menu-opened .right ul li .locations {
          margin: 0;
          outline: 1px #000 solid; }

.row {
  display: flex;
  padding: 0 4px;
  /*
	&:not(:last-child) {
		border-bottom: 1px #f5f5f5 solid;
	}
	*/ }
  .row > .column {
    flex: 25% 1;
    padding: 0 4px; }
  .row > .column-2 {
    flex: 50% 1; }
  .row > .column-3 {
    flex: 33% 1; }
  .row > .column-4 {
    flex: .25% 1; }

.button {
  color: #028f76;
  padding: 10px 40px;
  border: 1px #028f76 solid;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  margin: 10px;
  padding: 20px;
  cursor: pointer; }
  .button--block {
    width: 100%; }

.column img {
  margin-top: 8px;
  vertical-align: middle;
  max-width: 100%; }

.venue-logo {
  opacity: 1;
  -webkit-transition: 0.3s;
  transition: 0.3s; }
  .venue-logo:hover {
    opacity: 0.4; }

.venues-more {
  color: #028f76; }
  .venues-more h2 {
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: 4px; }
  .venues-more .venue {
    margin: 20px 0; }
    .venues-more .venue a {
      color: #666;
      text-decoration: none;
      opacity: 0.6;
      -webkit-transition: 0.3s;
      transition: 0.3s; }
      .venues-more .venue a:hover {
        color: #000;
        opacity: 1; }

.contact-options {
  width: 90%;
  margin: auto;
  color: #028f76; }
  .contact-options .column-2 {
    padding: 0 60px; }
  .contact-options .map {
    border: 1px #EEE solid;
    margin-bottom: 20px; }
  .contact-options .line {
    margin: 8px 0; }
  .contact-options h2 {
    font-weight: 700;
    color: #028f76;
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 2px;
    margin-bottom: 20px; }
    .contact-options h2::before {
      content: "\A0\A0\A0\A0";
      text-decoration: line-through;
      margin-right: 10px; }

/* Card Component */
.card {
  display: flex;
  align-items: center;
  margin: 80px 0; }
  .card--align-start {
    align-items: flex-start; }
  .card--row-reverse {
    flex-direction: row-reverse; }
  .card--no-margin {
    margin: 0; }
  .card--image-shadow img {
    box-shadow: 0px 0px 36px 4px #cccccc; }
  .card .column {
    flex: 1 1; }
    .card .column.text {
      margin: 0 40px; }
  .card__header {
    text-align: left;
    font-weight: 700;
    color: #028f76;
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 2px; }
    .card__header::before {
      content: "\A0\A0\A0\A0";
      text-decoration: line-through;
      margin-right: 10px; }
  .card__title {
    text-align: left;
    font-family: 'Playfair Display', serif;
    font-weight: 300;
    font-size: 40px;
    padding: 0;
    margin: 30px 0;
    line-height: 1; }
  .card__content {
    text-align: left;
    color: #666;
    font-size: 16px;
    line-height: 1.8;
    padding: 0;
    margin: 30px 0; }

/* Form Component */
.form {
  width: 100%; }
  .form__row {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .form__field {
    flex: 1 1;
    margin: 10px; }
    .form__field label {
      color: #028f76;
      display: block;
      padding: 10px 0;
      font-size: 14px; }
    .form__field input,
    .form__field textarea,
    .form__field select {
      padding: 20px;
      border: 1px #CCC solid;
      width: 100%; }
      .form__field input.error,
      .form__field textarea.error,
      .form__field select.error {
        color: #FFF;
        background-color: #c0392b;
        -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
                animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        -webkit-perspective: 1000px;
                perspective: 1000px; }
    .form__field select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEwAACxMBAJqcGAAAAvxJREFUeJzt3E2ID3Ecx/G3xw152oM8JQ+Rh5DDlptyECfl6OAiOXo4iYOLkps4Ui5byMFDe6AUkQMupEhKREJ5WLLlYZfDcFHs/uc/8/vOzP/9qs91Z+bzmXbb+c8uSJIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZKkTrMYOAf0Az/Nf/MROAssytV0BS0D3hNfbN3yDliSo+/K6SO+zLrmYo6+WzKq7AMAA8CEBMdpogFgUpkHGF3mF/9tMMExmupH2QdIcQP0JThGUzWiu9nAC+J/ntYtz4FZOfqupJX4K2Ar+QisyNV0hW0AvhNfbtXzDVifs+PK2058wVXPttzt1sQh4kuuag620Wut9BJfdtVyqq1Ga2Y8cJ340quSq8C4dgqto+nAI+LLj84DYGqbXdbWAuAN8SNE5RUwr+0Wa64H+EL8GKnzGVhTQH+NsJnsM4PoUVLlB7CpkOYaZBfxw6TKzoI6a5yjxI9Tdo4U1lYDjQbOEz9SWTlDmncwam0CcJv4sYrOTaCrwJ4abQbwlPjRispjoLvQhjrAUprxMulbYGHB3XSMdcBX4kfMmwFgbeGtdJitwBDxY7aaQWBLCX10pAPED9pq9pTSRAc7SfyoI82xkjroaGOBK8SPO1wukOZt6440BbhP/Mj/yh1gYmlXLwDmAi+JH/vvPCV7fqEEVgOfiB/9T96TPbdQQhupxmvmX8meVyjADmLHHyJ7TqFAh4m7AfYnuD4NYxRwmvTjn0hxcRqZLuAG6ca/TPZcQhXSTfaxa9nj3wMmJ7omtWgR2cevZY3/ApiT7GqUy1qyj2GLHr8fWJXwOtSGLRT7mvl3sj9vV43spbgbYHvic1dBjtP++IeSn7UKMwa4RP7xe9Ofsoo2EbhL6+NfI/szdjXATOAZIx//ITAt4kRVnuXAB4Yf/zUwP+YUVbYe/v8yyROyG0UNNgXYR/b6Vj/Zd4VbwG78n8aSJEmSJEmSJEmSJEmSJEmSJEmSJEmSJEmSJEmSJCnQL74K1b7KD2ipAAAAAElFTkSuQmCC");
      background-repeat: no-repeat;
      background-position: 90%;
      background-size: 13px 13px;
      -webkit-appearance: none;
      -moz-appearance: none; }
    .form__field textarea {
      height: 100px;
      resize: none; }
  .form__button {
    color: #FFF;
    background-color: #028f76; }

/* Category Component */
.category {
  text-align: center;
  cursor: pointer;
  padding: 10px !important; }
  .category__title {
    color: #028f76;
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: 4px;
    font-weight: 700;
    margin: 10px 0; }
    .category__title--active::before {
      content: "\A0\A0\A0\A0\A0";
      text-decoration: line-through;
      margin-right: 10px; }
  .category__description {
    color: #858585;
    line-height: 1.4;
    padding: 10px; }
  .category__image {
    box-shadow: 0px 0px 36px 4px #cccccc;
    margin: 20px 0; }

.homepage-quote {
  color: #FFF;
  background-image: url(/images/sections/homepage/quote.jpg);
  background-size: cover;
  padding: 200px 0;
  text-align: center; }
  .homepage-quote .button {
    color: #FFF;
    border: 1px #028f76 solid; }

.homepage-scroll {
  width: 300px;
  background-image: url(/images/sections/homepage/scroll.png);
  background-repeat: no-repeat;
  background-position: center;
  height: 310px;
  padding-top: 60px;
  position: absolute;
  margin-top: 30px;
  margin-left: calc(50% - 150px); }

.homepage-cards {
  margin-top: 200px; }

.events {
  width: 80%;
  margin: 100px auto 0; }

.slideshow {
  overflow: hidden;
  overflow-x: scroll;
  white-space: nowrap;
  margin: 20px 0 50px;
  -webkit-overflow-scrolling: touch; }
  .slideshow img {
    display: inline-block;
    margin-right: 20px; }
  .slideshow .navigation {
    display: block; }

.instagram {
  margin: 40px 0; }
  .instagram__follow-us {
    font-family: 'Playfair Display', serif;
    font-weight: 400;
    color: #000;
    font-size: 30px;
    padding: 0 40px; }
  .instagram__iframe {
    width: 100%;
    height: 220px;
    outline: 0;
    border: 0; }
    .instagram__iframe img {
      box-shadow: 0px 0px 36px 4px #cccccc; }
  .instagram__account {
    font-family: 'Playfair Display', serif;
    font-weight: 400;
    font-size: 30px;
    padding: 0 40px;
    text-align: right; }
    .instagram__account::before {
      content: "\A0\A0\A0\A0\A0\A0\A0\A0\A0";
      text-decoration: line-through;
      margin-right: 10px; }

/* Responsive */
@media only screen and (max-width: 600px) {
  header .menu-opened .right {
    width: 70%; }
  .row {
    display: block; }
    .row .column,
    .row .column-2 {
      flex: 100% 1;
      max-width: 100%; }
  .contact-options {
    width: 100%; }
    .contact-options .column-2 {
      width: 100%;
      padding: 0; } }

@media only screen and (max-width: 800px) {
  .homepage-scroll {
    display: none; }
  .hero .text {
    position: relative; }
  .hero__subtitle {
    max-width: 100%; }
  .hero__content {
    width: 100%; }
  .hero__image {
    float: none;
    max-width: 100%; }
  .card {
    display: block;
    text-align: center; }
    .card__header {
      margin-top: 20px; }
    .card__content {
      text-align: left; }
    .card .column {
      flex: none; }
      .card .column img {
        width: 80%;
        margin: auto; } }

.arrows {
  text-align: center;
  margin: 20px 0 50px; }
  .arrows > div {
    display: inline-block;
    margin: 20px;
    cursor: pointer; }
    .arrows > div:hover {
      opacity: 0.4; }

body {
  margin: 0;
  padding: 0;
  font-family: 'Muli', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

