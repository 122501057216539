@import "~slick-carousel/slick/slick.css";
//@import "~slick-carousel/slick/slick-theme.css";


html {
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

/* Helpers & Animations */

@mixin element($element) {
	&__#{$element} {
		@content;
	}
}

@mixin modifier($modifier) {
	&--#{$modifier} {
		@content;
	}
}

@mixin image-shadow() {
	box-shadow: 0px 0px 36px 4px rgba(204,204,204,1);
}

.clearfix::after {
	content: "";
	clear: both;
	display: table;
}

.isHidden {
	opacity: 0;
}

.isVisible {
    opacity: 1;
    transition: opacity 1000ms;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

/* Main SCSS */

.logo-header {
	display: inline-block;
	margin: 20px 40px;
}

.hero {
	width: 100%;
	padding: 40px;

	.text {
		position: absolute;
		margin-top: 60px;
	}

	@include element('title') {
		color: #028f76;
		text-transform: uppercase;
		font-size: 16px;
		font-weight: 700;
		letter-spacing: 6px;
	}

	@include element('subtitle') {
		font-family: 'Playfair Display', serif;
		font-weight: 400;
		font-size: 50px;
		padding: 0;
		margin: 30px 0;
		line-height: 60px;
		max-width: 60%;
	}

	@include element('content') {
		color: #858585;
		width: 350px;
		line-height: 1.8;
	}

	@include element('image') {
		float: right;
		@include image-shadow()
	}
}

.container {
	color: #333;
	background-size: cover;
	position: relative;
	width: 100%;
	min-height: 100vh;

	h1 {
		font-family: 'Playfair Display', serif;
		font-weight: 400;
		font-size: 50px;
		padding: 0;
		margin: 30px 0;
		line-height: 70px;
	}

	h2 {
		color: #FFF;
		text-transform: uppercase;
		font-size: 14px;
		letter-spacing: 6px;
		font-weight: 700;
	}

	&.light {
		color: #FFF;
	}

	&.dark {
		color: #000;
	}
}

.content {
	margin: 80px 0 40px;

	&.left {
		text-align: left;
	}

	&.center {
		text-align: center;
	}
}

footer {
	text-align: center;
	background-color: #f5f5f5;
	padding: 40px 20px;

	.logo {
		width: 200px;
		height: 100px;
		background-color: #CCC;
		margin: auto;
	}

	.links {
		margin: 20px 0;

		ul {
			padding: 0;
			margin: 0;

			li {
				list-style-type: none;
				display: inline-block;
				margin: 10px 30px;

				a {
					color: #7f988a;
					font-weight: 700;
					text-transform: uppercase;
					text-decoration: none;
					font-size: 16px;
				}
			}
		}
	}

	.contact {
		color: #98a79f;
		margin: 10px 0;
	}

	.legal {
		color: #98a79f;
		font-size: 14px;
	}
}

header {

	.menu-closed {

		.text {
			position: absolute;
			font-weight: 700;
			top: 30px;
			right: 60px;
		}

		.open-menu-icon {
			float: right;
			right: 0;
			top: 0;
			padding: 20px;
			margin: 10px;
			cursor: pointer;
		}

	}

	.menu-opened {

		background-image: url(/images/header/background.jpg);
		background-size: cover;

		.language-selector {

			padding: 20px;
			float: left;

			span {
				color: #028f76;
				margin: 0 10px;
				cursor: pointer;

				&.active {
					color: #000;
				}
			}
		}

		.close-menu-icon {
			padding: 20px;
			margin: 10px;
			cursor: pointer;
			display: inline-block;
		}

		background-color: #000;
		height: 100vh;

		.right {

			.locations {
				color: #028f76;
			}

			background-color: #FFF;
			width: 500px;
			float: right;
			height: 100vh;
			text-align: right;

			ul {
				padding: 15px;
				margin: 0;

				li {

					list-style-type: none;
					text-align: right;

					a {
						font-family: 'Playfair Display', serif;
						font-weight: 300;
						font-size: 28px;
						color: #3f4843;
						padding: 10px;
						display: inline-block;
						text-decoration: none;

						&.active {
							color: #028f76;

							&::before {
								content:"\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
								text-decoration:line-through;
								margin-right: 10px;
							}
						}
					}	

					.social-media {

						margin-top: 20px;

						img {
							margin: 0 10px;
						}
					}

					.locations {
						margin: 0;
						outline: 1px #000 solid;
					}
				}
			}

		}

	}
}


.row {
	display: flex;
	//flex-wrap: wrap;
	padding: 0 4px;

	/*
	&:not(:last-child) {
		border-bottom: 1px #f5f5f5 solid;
	}
	*/

	> .column {
		flex: 25%;
		padding: 0 4px;
	}

	> .column-2 {
		flex: 50%;
	}

	> .column-3 {
		flex: 33%;
	}

	> .column-4 {
		flex: .25%;
	}
}

.button {
	color: #028f76;
	padding: 10px 40px;
	border: 1px #028f76 solid;
	text-transform: uppercase;
	text-decoration: none;
	display: inline-block;
	text-align: center;

	font-weight: 500;
	font-size: 14px;
	margin: 10px;
	padding: 20px;
	cursor: pointer;

	@include modifier('block') {
		width: 100%;
	}
}

.column img {
	margin-top: 8px;
	vertical-align: middle;
	max-width: 100%;
}

.venue-logo {
	opacity: 1;
	transition: 0.3s;

	&:hover {
		opacity: 0.4;
	}
}

.venues-more {
	color: #028f76;

	h2 {
		text-transform: uppercase;
		font-size: 20px;
		letter-spacing: 4px;
	}

	.venue {
		margin: 20px 0;
		a {
			color: #666;
			text-decoration: none;
			opacity: 0.6;
			transition: 0.3s;

			&:hover {
				color: #000;
				opacity: 1;
			}
		}
	}
}

.contact-options {
	width: 90%;
	margin: auto;
	color: #028f76;

	.column-2 {
		padding: 0 60px;
	}

	.map {
		border: 1px #EEE solid;
		margin-bottom: 20px;
	}

	.line {
		margin: 8px 0;
	}

	h2 {
		font-weight: 700;
		color: #028f76;
		text-transform: uppercase;
		font-size: 18px;
		letter-spacing: 2px;
		margin-bottom: 20px;

		&::before {
			content:"\00a0\00a0\00a0\00a0";
			text-decoration:line-through;
			margin-right: 10px;
		}
	}
}

/* Card Component */

.card {
	display: flex;
	align-items: center;

	//animation: fadein 2s;
	margin: 80px 0;
	//opacity: 0.1;

	@include modifier('align-start') {
		align-items: flex-start;
	}

	@include modifier('row-reverse') {
		flex-direction: row-reverse;
	}

	@include modifier('no-margin') {
		margin: 0;
	}

	@include modifier('image-shadow') {
		img {
			@include image-shadow()
		}
	}

	.column {
		flex: 1;

		&.text {
			margin: 0 40px;
		}
	}

	@include element('header') {
		text-align: left;
		font-weight: 700;
		color: #028f76;
		text-transform: uppercase;
		font-size: 18px;
		letter-spacing: 2px;

		&::before {
			content:"\00a0\00a0\00a0\00a0";
			text-decoration:line-through;
			margin-right: 10px;
		}
	}

	@include element('title') {
		text-align: left;
		font-family: 'Playfair Display', serif;
		font-weight: 300;
		font-size: 40px;
		padding: 0;
		margin: 30px 0;
		line-height: 1;
	}

	@include element('content') {
		text-align: left;
		color: #666;
		font-size: 16px;
		line-height: 1.8;
		padding: 0;
		margin: 30px 0;
	}
}

/* Form Component */

.form {

	width: 100%;

	@include element('row') {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}


	@include element('field') {

		flex: 1;
		margin: 10px;

		label {
			color: #028f76;
			display: block;
			padding: 10px 0;
			font-size: 14px;
		}

		input,
		textarea,
		select {
			padding: 20px;
			border: 1px #CCC solid;
			width: 100%;

			&.error {
				color: #FFF;
				background-color: #c0392b;
				animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
				transform: translate3d(0, 0, 0);
				backface-visibility: hidden;
				perspective: 1000px;
			}
		}

		select {

			background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEwAACxMBAJqcGAAAAvxJREFUeJzt3E2ID3Ecx/G3xw152oM8JQ+Rh5DDlptyECfl6OAiOXo4iYOLkps4Ui5byMFDe6AUkQMupEhKREJ5WLLlYZfDcFHs/uc/8/vOzP/9qs91Z+bzmXbb+c8uSJIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZIkSZKkTrMYOAf0Az/Nf/MROAssytV0BS0D3hNfbN3yDliSo+/K6SO+zLrmYo6+WzKq7AMAA8CEBMdpogFgUpkHGF3mF/9tMMExmupH2QdIcQP0JThGUzWiu9nAC+J/ntYtz4FZOfqupJX4K2Ar+QisyNV0hW0AvhNfbtXzDVifs+PK2058wVXPttzt1sQh4kuuag620Wut9BJfdtVyqq1Ga2Y8cJ340quSq8C4dgqto+nAI+LLj84DYGqbXdbWAuAN8SNE5RUwr+0Wa64H+EL8GKnzGVhTQH+NsJnsM4PoUVLlB7CpkOYaZBfxw6TKzoI6a5yjxI9Tdo4U1lYDjQbOEz9SWTlDmncwam0CcJv4sYrOTaCrwJ4abQbwlPjRispjoLvQhjrAUprxMulbYGHB3XSMdcBX4kfMmwFgbeGtdJitwBDxY7aaQWBLCX10pAPED9pq9pTSRAc7SfyoI82xkjroaGOBK8SPO1wukOZt6440BbhP/Mj/yh1gYmlXLwDmAi+JH/vvPCV7fqEEVgOfiB/9T96TPbdQQhupxmvmX8meVyjADmLHHyJ7TqFAh4m7AfYnuD4NYxRwmvTjn0hxcRqZLuAG6ca/TPZcQhXSTfaxa9nj3wMmJ7omtWgR2cevZY3/ApiT7GqUy1qyj2GLHr8fWJXwOtSGLRT7mvl3sj9vV43spbgbYHvic1dBjtP++IeSn7UKMwa4RP7xe9Ofsoo2EbhL6+NfI/szdjXATOAZIx//ITAt4kRVnuXAB4Yf/zUwP+YUVbYe/v8yyROyG0UNNgXYR/b6Vj/Zd4VbwG78n8aSJEmSJEmSJEmSJEmSJEmSJEmSJEmSJEmSJEmSJCnQL74K1b7KD2ipAAAAAElFTkSuQmCC');
			background-repeat: no-repeat;
			background-position: 90%;
			background-size: 13px 13px;
			-webkit-appearance: none; 
			-moz-appearance: none;
		}

		textarea {
			height: 100px;
			resize: none;
		}

	}


	@include element('button') {
		color: #FFF;
		background-color: #028f76;
	}

}

/* Category Component */

.category {

	text-align: center;
	cursor: pointer;
	padding: 10px !important;

	@include element('title') {
		color: #028f76;
		text-transform: uppercase;
		font-size: 20px;
		letter-spacing: 4px;
		font-weight: 700;
		margin: 10px 0;

		@include modifier('active') {

			&::before {
				content:"\00a0\00a0\00a0\00a0\00a0";
				text-decoration:line-through;
				margin-right: 10px;
			}
		}
	}

	@include element('description') {
		color: #858585;
		line-height: 1.4;
		padding: 10px;
	}

	@include element('image') {
		@include image-shadow();
		margin: 20px 0;
	}
}

.homepage-quote {
	color: #FFF;
	background-image: url(/images/sections/homepage/quote.jpg);
	background-size: cover;
	padding: 200px 0;
	text-align: center;

	.button {
		color: #FFF;
		border: 1px #028f76 solid;
	}
}

.homepage-scroll {
	width: 300px;
	background-image: url(/images/sections/homepage/scroll.png);
	background-repeat: no-repeat;
	background-position: center;
	height: 310px;
	padding-top: 60px;
	position: absolute;
	margin-top: 30px;
	margin-left: calc(50% - 150px);
}

.homepage-cards {
	margin-top: 200px;
}

.events {
	width: 80%;
	margin: 100px auto 0;
}

.slideshow {

	overflow: hidden;
	overflow-x: scroll;
	white-space: nowrap;
	margin: 20px 0 50px;
	-webkit-overflow-scrolling: touch;

	img {
		display: inline-block;
		margin-right: 20px;
	}

	.navigation {
		display: block;
	}
}

.instagram {

	margin: 40px 0;

	@include element('follow-us') {
		font-family: 'Playfair Display', serif;
		font-weight: 400;
		color: #000;
		font-size: 30px;
		padding: 0 40px;
	}

	@include element('iframe') {
		width: 100%;
		height: 220px;
		outline: 0;
		border: 0;

		img {
			@include image-shadow()
		}
	}

	@include element('account') {
		font-family: 'Playfair Display', serif;
		font-weight: 400;
		font-size: 30px;
		padding: 0 40px;
		text-align: right;
		&::before {
			content:"\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
			text-decoration:line-through;
			margin-right: 10px;
		}
	}
}

/* Responsive */


@media only screen and (max-width: 600px) {

	header {
		.menu-opened {
			.right {
				width: 70%;
			}
		}
	}

	.row {

		display: block;

		.column,
		.column-2 {
			flex: 100%;
			max-width: 100%;
		}
	}

	.contact-options {
		width: 100%;

		.column-2 {
			width: 100%;
			padding: 0;
		}
	}

}

@media only screen and (max-width: 800px) {

	.homepage-scroll {
		display: none;
	}

	.hero {

		.text {
			position: relative;
		}

		@include element('subtitle') {
			max-width: 100%;
		}

		@include element('content') {
			width: 100%;
		}

		@include element('image') {
			float: none;
			max-width: 100%;
		}

	}

	.card {

		display: block;
		text-align: center;

		@include element('header') {
			margin-top: 20px;
		}

		@include element('content') {
			text-align: left;
		}

		.column {
			flex: none;

			img {
				width: 80%;
				margin: auto;
			}
		}
    }
}

.arrows {
	text-align: center;
	margin: 20px 0 50px;

	> div {
		display: inline-block;
		margin: 20px;
		cursor: pointer;

		&:hover {
			opacity: 0.4;
		}
	}
}